<template>
  <div>
    <BaseNav></BaseNav>
    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <!-- Login card -->
          <form
            class="login-form"
            ref="login-form"
            @submit.prevent="submitLogin"
          >
            <div class="card mb-0 shadow rounded">
              <div class="card-body">
                <div class="text-center mb-3">
                  <i
                    class="
                      icon-reading icon-2x
                      text-slate-300
                      border-slate-300 border-3
                      rounded-round
                      p-3
                      mb-3
                      mt-1
                    "
                  ></i>
                  <h5 class="mb-0">Login to your account</h5>
                  <span class="d-block text-muted">Your credentials</span>
                </div>

                <!-- <div class="alert alert-success my-2">
                  <p>
                    Admin Demo: admin@academiawebsites.com <br />
                    Password:demo<br /><br />
                    Editor Demo: editor@academiawebsites.com<br />
                    Password: demo<br /><br />
                    Writer Demo: writer@academiawebsites.com<br />
                    Password: demo
                  </p>
                </div> -->

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="text"
                    v-model="loginForm.email"
                    name="email"
                    class="form-control"
                    required
                    placeholder="Username"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-user text-muted"></i>
                  </div>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="password"
                    v-model="loginForm.password"
                    name="password"
                    class="form-control"
                    required
                    placeholder="Password"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-lock2 text-muted"></i>
                  </div>
                </div>

                <div class="form-group d-flex align-items-center">
                  <!-- <div class="form-check mb-0">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        name="remember"
                        class="form-input-styled"
                        checked
                        data-fouc
                      />
                      Remember
                    </label>
                  </div> -->

                  <router-link :to="{ name: 'ForgotPassword' }" class="ml-auto"
                    >Forgot password?</router-link
                  >
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    :disabled="loginForm.busy"
                    class="btn bg-primary-400 btn-block"
                  >
                    {{ loginForm.busy ? "Loading.." : "Sign in"
                    }}<i class="icon-circle-right2 ml-2"></i>
                  </button>
                </div>

                <!-- <div class="form-group text-center text-muted content-divider">
                  <span class="px-2">or sign in with</span>
                </div>

                <div class="form-group text-center">
                  <button type="button" class="btn btn-outline bg-indigo border-indigo text-indigo btn-icon rounded-round border-2"><i class="icon-facebook"></i></button>
                  <button type="button" class="btn btn-outline bg-pink-300 border-pink-300 text-pink-300 btn-icon rounded-round border-2 ml-2"><i class="icon-dribbble3"></i></button>
                  <button type="button" class="btn btn-outline bg-slate-600 border-slate-600 text-slate-600 btn-icon rounded-round border-2 ml-2"><i class="icon-github"></i></button>
                  <button type="button" class="btn btn-outline bg-info border-info text-info btn-icon rounded-round border-2 ml-2"><i class="icon-twitter"></i></button>
                </div> -->

                <div class="form-group text-center text-muted content-divider">
                  <span class="px-2">Don't have an account?</span>
                </div>

                <div class="form-group">
                  <router-link
                    :to="{ name: 'Register' }"
                    class="btn btn-light btn-block"
                    >Sign up</router-link
                  >
                </div>

                <span class="form-text text-center text-muted"
                  >By continuing, you're confirming that you've read our
                  <a href="#">Terms &amp; Conditions</a> and
                  <a href="#">Cookie Policy</a></span
                >
              </div>
            </div>
          </form>
          <!-- /login card -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Alerts from "../../../mixins/alerts";
export default {
  computed: {
    ...mapState(["auth/is_logged_in", "auth/user"]),
  },
  data() {
    return {
      loginForm: new this.$Form({
        email: "",
        password: "",
        token: "",
      }),
    };
  },
  methods: {
    ...mapActions("auth", ["doGetAuthUser"]),
    submitLogin() {
      this.$req
        .apiFormPost("/Auth/Login/", this.loginForm)
        .then((res) => {
          localStorage.setItem("token", `Bearer ${res.data.Data.access_token}`);
          document.location.href = "/home/";
        })
        .catch((err) => this.errorNoti(err));

      // this.doLogin(this.loginForm)
      //   .then((res) => {
      //     // console.log(res.data.Data);
      //     document.location.href = "/home/";
      //   })
      //   .catch((err) => {
      //     this.$notify({
      //       title: "Error",
      //       text: err.response.data ? err.response.data.Message : "Error",
      //       style: "danger",
      //     });
      //   });
    },
  },
  mounted() {
    this.$parent.$emit("loader:hide");
  },
  mixins: [Alerts],
};
</script>
